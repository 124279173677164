<template>
    <!-- Portfolio section start -->
	<section id="portfolio-section" class="px-4 lg:px-8 py-16  relative scroll-mt-20" ref="portfolio">
        <!-- Text wrapper start -->
        <div class="flex gap-8 flex-col md:flex-row">
            <!-- Main text start -->
            <h1 class="uppercase text-5xl mb-4 font-semibold ">
                ONS PORTFOLIO
            </h1>
            <p class="capitalize xl:w-1/2 text-stone-500 mb-8">
            </p>
            <!-- Main text end -->
        </div>
        <!-- Text wrapper end -->

        <!-- Images grid start -->
		<div class="grid-cols-2 md:grid-cols-4 grid mb-8 gap-4">
            <div class="grid grid-cols-1 md:hidden gap-4">

								<div class="group relative w-full h-full">
									<div class="flex h-full absolute bottom-0 left-0 right-0 transform-gpu opacity-0 group-hover:opacity-60 transform transition duration-300 ease-in-out group-hover:bg-gray-900 z-10 rounded-xl group-hover:scale-105 text-center">
										<div class="flex w-full place-items-center justify-center text-white text-xl p-4 select-none">Energiedelen Huset, Gent</div>
									</div>
									<img src="@/assets/portfolio/huset_2.jpg" alt="1" class="rounded-xl group-hover:scale-105 transition duration-300 ease-in-out -z-20">
								</div>
								<div class="group relative w-full h-full">
									<div class="flex h-full absolute bottom-0 left-0 right-0 transform-gpu opacity-0 group-hover:opacity-60 transform transition duration-300 ease-in-out group-hover:bg-gray-900 z-10 rounded-xl group-hover:scale-105 text-center">
										<div class="flex w-full place-items-center justify-center text-white text-xl p-4 select-none">Energiedelen @Zevenbergen, Brugge</div>
									</div>
									<img src="@/assets/portfolio/zevenbergen.jpg" alt="1" class="rounded-xl group-hover:scale-105 transition duration-300 ease-in-out -z-20">
								</div>
								<div class="group relative w-full h-full">
									<div class="flex h-full absolute bottom-0 left-0 right-0 transform-gpu opacity-0 group-hover:opacity-60 transform transition duration-300 ease-in-out group-hover:bg-gray-900 z-10 rounded-xl group-hover:scale-105 text-center">
										<div class="flex w-full place-items-center justify-center text-white text-xl p-4 select-none">Energie-advies residentie Sint-Jacobsnieuwstraat, Gent</div>
									</div>
									<img src="@/assets/portfolio/sintjacobs.jpg" alt="1" class="rounded-xl group-hover:scale-105 transition duration-300 ease-in-out -z-20">
								</div>
								<div class="group relative w-full h-full">
									<div class="flex h-full absolute bottom-0 left-0 right-0 transform-gpu opacity-0 group-hover:opacity-60 transform transition duration-300 ease-in-out group-hover:bg-gray-900 z-10 rounded-xl group-hover:scale-105 text-center">
										<div class="flex w-full place-items-center justify-center text-white text-xl p-4 select-none">Ontwikkeling EMS D'Ieteren, Kortenberg</div>
									</div>
									<img src="@/assets/portfolio/dieterenbatterij.jpg" alt="1" class="rounded-xl group-hover:scale-105 transition duration-300 ease-in-out -z-20">
								</div>




            </div>

            <div class="grid grid-cols-1 md:hidden gap-4">
								<div class="group relative w-full h-full">
									<div class="flex h-full absolute bottom-0 left-0 right-0 transform-gpu opacity-0 group-hover:opacity-60 transform transition duration-300 ease-in-out group-hover:bg-gray-900 z-10 rounded-xl group-hover:scale-105 text-center">
										<div class="flex w-full place-items-center justify-center text-white text-xl p-4 select-none">Energiedelen @Ulobia, Gent</div>
									</div>
									<img src="@/assets/portfolio/Ulobia.jpg" alt="1" class="rounded-xl group-hover:scale-105 transition duration-300 ease-in-out -z-20">
								</div>
								<div class="group relative w-full h-full">
									<div class="flex h-full absolute bottom-0 left-0 right-0 transform-gpu opacity-0 group-hover:opacity-60 transform transition duration-300 ease-in-out group-hover:bg-gray-900 z-10 rounded-xl group-hover:scale-105 text-center">
										<div class="flex w-full place-items-center justify-center text-white text-xl p-4 select-none">Energie-advies @Lamot-site, Mechelen</div>
									</div>
									<img src="@/assets/portfolio/lamot.jpg" alt="1" class="rounded-xl group-hover:scale-105 transition duration-300 ease-in-out -z-20">
								</div>

								<div class="group relative w-full h-full">
									<div class="flex h-full absolute bottom-0 left-0 right-0 transform-gpu opacity-0 group-hover:opacity-60 transform transition duration-300 ease-in-out group-hover:bg-gray-900 z-10 rounded-xl group-hover:scale-105 text-center">
										<div class="flex w-full place-items-center justify-center text-white text-xl p-4 select-none">Energie-advies @De Klijte, Zwevegem</div>
									</div>
									<img src="@/assets/portfolio/deklijte.jpg" alt="1" class="rounded-xl group-hover:scale-105 transition duration-300 ease-in-out -z-20">
								</div>
								<div class="group relative w-full h-full">
									<div class="flex h-full absolute bottom-0 left-0 right-0 transform-gpu opacity-0 group-hover:opacity-60 transform transition duration-300 ease-in-out group-hover:bg-gray-900 z-10 rounded-xl group-hover:scale-105 text-center">
										<div class="flex w-full place-items-center justify-center text-white text-xl p-4 select-none">Energie-advies residentie Athena I en II, Gent</div>
									</div>
									<img src="@/assets/portfolio/athena.jpg" alt="1" class="rounded-xl group-hover:scale-105 transition duration-300 ease-in-out -z-20">
								</div>
            </div>

            <div class="hidden md:flex flex-col gap-4">
							<div class="group relative w-full h-full">
                <img src="@/assets/portfolio/huset_2.jpg" alt="1" class="rounded-xl group-hover:scale-105 transition duration-300 ease-in-out -z-20">
								<div class="flex h-full absolute bottom-0 left-0 right-0 transform-gpu opacity-0 group-hover:opacity-60 transform transition duration-300 ease-in-out group-hover:bg-gray-900 z-10 rounded-xl group-hover:scale-105 text-center">
									<div class="flex w-full place-items-center justify-center text-white text-xl p-4">Energiedelen @Huset, Gent</div>
								</div>
							</div>
							<div class="group relative w-full h-full">
                <img src="@/assets/portfolio/Ulobia.jpg" alt="2" class="rounded-xl group-hover:scale-105 transition duration-300 ease-in-out -z-20">
								<div class="flex h-full absolute bottom-0 left-0 right-0 transform-gpu opacity-0 group-hover:opacity-60 transform transition duration-300 ease-in-out group-hover:bg-gray-900 z-10 rounded-xl group-hover:scale-105 text-center">
									<div class="flex w-full place-items-center justify-center text-white text-xl p-4">Energiedelen @Ulobia, Gent</div>
								</div>
							</div>
						</div>
            <div class="hidden md:flex flex-col gap-4">
							<div class="group relative w-full h-full">
                <img src="@/assets/portfolio/zevenbergen.jpg" alt="1" class="rounded-xl group-hover:scale-105 transition duration-300 ease-in-out -z-20">
								<div class="flex h-full absolute bottom-0 left-0 right-0 transform-gpu opacity-0 group-hover:opacity-60 transform transition duration-300 ease-in-out group-hover:bg-gray-900 z-10 rounded-xl group-hover:scale-105 text-center">
									<div class="flex w-full place-items-center justify-center text-white text-xl p-4">Energiedelen @Zevenbergen, Brugge</div>
								</div>
							</div>
							<div class="group relative w-full h-full">
                <img src="@/assets/portfolio/sintjacobs.jpg" alt="2" class="rounded-xl group-hover:scale-105 transition duration-300 ease-in-out -z-20">
								<div class="flex h-full absolute bottom-0 left-0 right-0 transform-gpu opacity-0 group-hover:opacity-60 transform transition duration-300 ease-in-out group-hover:bg-gray-900 z-10 rounded-xl group-hover:scale-105 text-center">
									<div class="flex w-full place-items-center justify-center text-white text-xl p-4">Energie-advies @Sintjacobsnieuwstraat, Gent</div>
								</div>
							</div>
            </div>
            <div class="hidden md:flex flex-col gap-4">
							<div class="group relative w-full h-full">
                <img src="@/assets/portfolio/lamot.jpg" alt="1" class="rounded-xl group-hover:scale-105 transition duration-300 ease-in-out -z-20">
								<div class="flex h-full absolute bottom-0 left-0 right-0 transform-gpu opacity-0 group-hover:opacity-60 transform transition duration-300 ease-in-out group-hover:bg-gray-900 z-10 rounded-xl group-hover:scale-105 text-center">
									<div class="flex w-full place-items-center justify-center text-white text-xl p-4"> Energie-advies @Lamot, Mechelen </div>
								</div>
							</div>
							<div class="group relative w-full h-full">
                <img src="@/assets/portfolio/deklijte.jpg" alt="2" class="rounded-xl group-hover:scale-105 transition duration-300 ease-in-out -z-20">
								<div class="flex h-full absolute bottom-0 left-0 right-0 transform-gpu opacity-0 group-hover:opacity-60 transform transition duration-300 ease-in-out group-hover:bg-gray-900 z-10 rounded-xl group-hover:scale-105 text-center">
									<div class="flex w-full place-items-center justify-center text-white text-xl p-4"> Energie-advies @De Klijte, Zwevegem </div>
								</div>
							</div>
            </div>
            <div class="hidden md:flex flex-col gap-4">
							<div class="group relative w-full h-full">
                <img src="@/assets/portfolio/dieterenbatterij.jpg" alt="1" class="rounded-xl group-hover:scale-105 transition duration-300 ease-in-out -z-20">
								<div class="flex h-full absolute bottom-0 left-0 right-0 transform-gpu opacity-0 group-hover:opacity-60 transform transition duration-300 ease-in-out group-hover:bg-gray-900 z-10 rounded-xl group-hover:scale-105 text-center">
									<div class="flex w-full place-items-center justify-center text-white text-xl p-4"> Ontwikkelen EMS D'Ieteren, Kortenberg </div>
								</div>
							</div>
							<div class="group relative w-full h-full">
                <img src="@/assets/portfolio/athena.jpg" alt="2" class="rounded-xl group-hover:scale-105 transition duration-300 ease-in-out -z-20">
								<div class="flex h-full absolute bottom-0 left-0 right-0 transform-gpu opacity-0 group-hover:opacity-60 transform transition duration-300 ease-in-out group-hover:bg-gray-900 z-10 rounded-xl group-hover:scale-105 text-center">
									<div class="flex w-full place-items-center justify-center text-white text-xl p-4"> Energie-advies residentie Athena I en II, Gent </div>
								</div>
							</div>
            </div>
        </div>
		<!-- Images grid end -->

        <!-- "More" link start >
        <a href="#" class="text-end">
            <p class="font-semibold text-lg group relative">
                <span>Show more photos </span>
                <i class="fa-solid fa-arrow-right"></i>
            </p>
        </a>
        < "More" link end -->

        <!-- Circle start -->
        <div class="bg-neutral-300 h-64 w-64 rounded-full absolute top-0 left-0 mt-16 -z-20"></div>
        <!-- Circle end -->
	</section>
	<!-- Portfolio section end -->
</template>

<script>
export default {

}
</script>

<style>

</style>
