<template>
	<!-- Navbar start -->
	<navbar-element @scroll-to="scrollTo"></navbar-element>
	<!-- Navbar end -->

	<!-- Rendered view start -->
	<router-view @scroll-to="scrollTo" ref="routerView"> </router-view>
	<!-- Rendered view end -->

	<!-- Footer start -->
	<footer-element></footer-element>
	<!-- Footer end -->
</template>

<script>
import NavbarElement from '@/components/NavbarElement.vue';
import FooterElement from '@/components/FooterElement.vue';
import HomeView from '@/views/HomeView.vue';

export default {
	components: {
		FooterElement,
		NavbarElement,
	},
	mounted() {
	},
	methods: {
		scrollTo(ref) {
			// ref always of form "<section-name>-section"
			let subsection = ref.slice(0, -8);
			const componentInstance = this.$refs.routerView.$.subTree.component?.proxy;
      const targetComponent = componentInstance?.$refs?.[ref];
			if (targetComponent){
				componentInstance.$refs[ref].$refs[subsection].scrollIntoView({behavior: "smooth"});
			} else {
        this.$router.push('/');
      }
		}
	}
}
</script>

<style>

</style>
