<template>
	<section class="pl-16 pr-4 md:px-28 lg:px-32 pt-16 pb-8 border-t border-stone-500 relative scroll-mt-20" ref="about">
		<h1 class="uppercase text-5xl mb-4 font-semibold ">
			Over ons
		</h1>
		<p class="xl:w-1/2 text-stone-500 mb-8 text-lg">
			<span class="text-emoni-orange font-bold"> EMONI </span> staat voor <span class="text-emoni-orange font-bold">E</span>nergie <span class="text-emoni-orange font-bold">MONI</span>tor, een organisatie met een passie voor energie-analyses en optimalisering. Met als slagzin <span class="italic">meten is weten, gissen is missen</span> realiseren wij projecten voor groot (D'Ieteren, Reynaers aluminium, Shell, ...) en klein (zelfstandigen, co-housings en particulieren).
		</p>
		<p class="xl:w-1/2 text-stone-500 mb-8 text-lg">
			<span class="text-emoni-orange font-bold">EMONI</span> ontwikkelde zo een apparaat dat lokale energiestromen in kaart brengt. Dit vormt doorgaans de basis voor onze diensten.
		</p>
    <div class="bg-emoni-orange h-32 w-32 md:h-50 md:w-50 rounded-full absolute top-0 -left-20 mt-16 -z-20"></div>
    <!-- Circle end -->
	</section>
</template>

<script>
export default {
}
</script>

<style>
</style>
