<template>
    <!-- Footer start -->
    <div class="bg-stone-700 w-full flex justify-center items-center md:justify-evenly flex-col md:flex-row gap-8 p-8">
        <!-- Overview start -->
        <div class="text-stone-100 text-center">
            <p class="tracking-widest font-semibold text-stone-100">EMONI</p>
            <p class="tracking-widest font-light text-stone-100">slim omgaan met energie</p>
        </div>
        <!-- Overview end -->

        <!-- Links start>
        <div class="text-stone-100 flex justify-center items-center gap-8">
			<a href="#">
				<i class="fa-brands fa-instagram socialIcon"></i>
			</a>
			<a href="#">
				<i class="fa-brands fa-linkedin socialIcon"></i>
			</a>
			<a href="#">
				<i class="fa-brands fa-facebook socialIcon"></i>
			</a>
			<a href="#">
				<i class="fa-brands fa-dribbble socialIcon"></i>
			</a>
			<a href="#">
				<i class="fa-brands fa-pinterest socialIcon"></i>
			</a>
        </div>
        <Links end -->
    </div>
    <!-- Footer end -->
</template>

<script>
export default {

}
</script>

<style>

</style>
