import { createWebHistory, createRouter } from "vue-router";

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/HomeView.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/energiedelen",
    name: "energiedelen",
    component: () => import("../views/EnergiedelenCohousing.vue"),
    meta: { requiresAuth: false },
  }
]

const router = createRouter({
	scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
      }
    }
  },
  history: createWebHistory(),
  routes,
});


export default router;
