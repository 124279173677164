<template>
<section id="services-section" class="pl-8 pr-8 lg:px-32 py-16 relative overflow-hidden scroll-mt-20" ref="service">
    <div class="flex flex-col md:flex-row">
      <h1 class="uppercase text-5xl mb-4 font-semibold ">
          Onze diensten
      </h1>
      <p class="xl:w-1/2 text-stone-500 mb-8 text-lg"></p>
    </div>

  <div class="flex flex-col md:flex-row md:gap-8">
    <div class="flex flex-row justify-between justify-start md:items-start my-8 w-full md:w-1/2">
      <div class="w-1/3 md:w-1/4 my-auto">
        <img src="@/assets/services/dimension-symbol.svg" alt="tools for measuring" class="-z-50 md:mb-4 mx-auto">
      </div>
      <div class="w-2/3 md:w-3/4 text-right md:text-left md:pr-0 my-auto">
        <h1 class="uppercase text-2xl font-semibold">
            Energiestudies
        </h1>
          <p class="text-stone-500 text-lg">
            De energietransitie heeft opportuniteiten en valkuilen. Onze onafhankelijke expertise helpt u het onderscheid te maken in wat relevant is en wat niet. Als voorbeeld <a href="https://drive.google.com/file/d/1IKwhCU9j6052wLT1b2uOUb3MT14P6RaP/view?usp=sharing" class="text-emoni-blue hover:underline"> het Emoni rapport over thuisbatterijen</a>, wat leidde tot de nodige aanpassingen van de batterijsimulator van de Vlaamse overheid.
          </p>
      </div>
    </div>
    <div class="flex flex-row justify-between items-center md:items-start my-8 w-full md:w-1/2">
      <div class="w-2/3 text-left md:w-full md:order-2 md:pr-0 my-auto">
        <h1 class="uppercase text-2xl font-semibold">
            Echt energiedelen
        </h1>
        <p class="text-stone-500 text-lg"> Het Vlaamse energiedelen slaat de bal mis. Emoni implementeert energiedelen zoals het zou moeten zijn: zonder taksen, heffingen, transmissiekosten of distributiekosten op lokaal geproduceerde en verbruikte energie.
        <RouterLink class="text-emoni-blue hover:underline" to="/energiedelen"> <span>&#8594;</span> Lees hier meer </RouterLink>
        </p>
      </div>
      <div class="w-1/3 md:w-1/4 my-auto">
        <img src="@/assets/services/sharing_cohousing.svg" alt="Sharing" class="-z-50 md:mb-4 md:order-1 mx-auto">
      </div>
    </div>
  </div>
  <div class="bg-emoni-blue h-32 w-32 md:h-50 md:w-50 rounded-full absolute top-0 -right-20 mt-16 -z-20"></div>
  </section>
</template>

<script>
export default {
}
</script>

<style>
</style>
